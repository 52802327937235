<template>
    <v-card
        class="rounded-xl card"
        :class="{ activeCard: chapterIsActive }"
        flat
        outlined
        @click="goToChapter(chapterListIndex)"
    >
        <div class="content-wrapper">
            <v-list-item-content>
                <div class="mt-4 mb-2 duration">{{ this.duration }}</div>
                <v-list-item-title class="card-title mb-1">
                    {{ this.title }}
                </v-list-item-title>
                <p class="subtitle">
                    {{ this.subtitle }}
                </p>
            </v-list-item-content>
            <v-spacer></v-spacer>
            <!-- <v-list-item-avatar
                tile
                size="20"
                color="grey"
            ></v-list-item-avatar> -->

            <v-card-actions class="button-wrapper">
                <v-row justify="end">
                    <v-btn
                        v-if="!chapterIsActive"
                        outlined
                        rounded
                        text
                        class="action-button"
                        @click="goToChapter(chapterListIndex)"
                    >
                        {{ $store.state.texts.chapter_card_button }}
                    </v-btn>
                </v-row>
            </v-card-actions>
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'ChapterCard',

    props: [
        'title',
        'duration',
        'subtitle',
        'chapterId',
        'courseId',
        'chapterListIndex',
        'activeChapter',
    ],

    data() {
        return {};
    },

    mounted() {},

    computed: {
        chapterIsActive: function determineIfCardIsActive() {
            let active = false;

            if (
                parseInt(this.activeChapter, 10) ===
                parseInt(this.chapterListIndex, 10)
            ) {
                active = true;
            } else {
                active = false;
            }

            return active;
        },
    },

    methods: {
        goToChapter() {
            this.$emit('clicked', this.chapterListIndex);
            window.scrollTo(0, 0);
        },
    },
};
</script>

<style scoped>
.card {
    background-color: var(--v-chapter_card_background-base);
}
.activeCard {
    border-width: 4px !important;
    border-color: var(--v-chapter_card_active_border-base) !important;
}

.action-button {
    font-family: "Circe-rounded", sans-serif;
    font-weight: 600;
    text-transform: none;
    background-color: var(--v-button-base) !important;
    padding: 20px !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    color: var(--v-button_text-base);
    border-width: 0px;
}

.title {
    color: var(--v-chapter_card_text-base);
    font-size: '18px';
    font-family: "Circe-rounded", sans-serif;
}

.subtitle {
    color: #29324a;
    font-weight: 500;
    font-size: 16px;
    font-family: "Circe-rounded", sans-serif;
    line-height: 1.6;
}

.card-padding {
    padding-left: 20px;
    padding-bottom: 10px;
}

.duration {
    color: var(--v-chapter_card_subheader-base);
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: "Circe-rounded", sans-serif;
    text-transform: none !important;
}

.card-title {
    color: var(--v-chapter_card_text-base);
    font-size: 20px;
    font-weight: 600 !important;
    font-family: "Circe-rounded", sans-serif;
    text-transform: none !important;
}

.content-wrapper {
    padding-left: 30px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.button-wrapper {
    padding-bottom: 0px;
    padding-right: 0px;
}
</style>
