<template>
    <v-container v-if="loading === false" class="course-background" fluid>
        <v-row ref="sub" class="padding pl-5 subheader">
            <!-- CONTENT - VIDEO/ LINK / INFORMATION -->
            <v-col cols="auto" xs="12" sm="12" md="7" lg="8" justify="center" text-align="center">
                <h4 class="go-back pt-2 pb-2 text" @click="onGoBackClick">
                    <v-icon class="text" style="margin-right: 5px">mdi-arrow-left</v-icon>
                    {{ $store.state.texts.course_page_back_to_courses }}
                </h4>
            </v-col>
        </v-row>
        <v-row class="padding mt-10 pl-5 pr-5">
            <v-col cols="auto" xs="12" sm="12" lg="8" justify="center" text-align="center">
                <h1 style="color: #d77f82">{{ course.displayName }}</h1>

                <video v-if="this.course.chapters[this.activeChapter].videoUrl.length > 1"
                    id="chapterVideo" controls :src="this.videoUrl" width="100%"
                    class="rounded-xl video-spacer" controlsList="nodownload"
                    oncontextmenu="return false;"></video>
                <h3 style="margin-top: 25px; margin-bottom: 0px; color: #d77f82">
                    {{ course.chapters[activeChapter].displayName }}
                </h3>

                <ChapterContentCard title="" :body="course.chapters[activeChapter].information">
                </ChapterContentCard>
                <ChapterContentCard title="" :body="course.chapters[activeChapter].linkText">
                </ChapterContentCard>

                <!-- LINK CARD SECTION -->
                <v-row>
                    <v-col cols="auto" v-for="link in course.chapters[activeChapter].links"
                        :key="link.id">
                        <LinkCard :icon="link.icon" :displayName="link.displayName" :url="link.url"
                            :color="course.primaryColor"></LinkCard>
                    </v-col>
                </v-row>

                <h3 v-if="course.chapters[activeChapter].authors[0]"
                style="text-align: center; margin-top: 50px">
                    {{ $store.state.texts.course_page_author_heading }}
                </h3>

                <AuthorCard v-if="course.chapters[activeChapter].authors[0]"
                :name="course.chapters[activeChapter].authors[0].name" :jobTitle="
                    course.chapters[activeChapter].authors[0].workTitle
                " :body="
    course.chapters[activeChapter].authors[0].describtion
" :image="course.chapters[activeChapter].authors[0].image"></AuthorCard>
            </v-col>
            <!-- CHAPTER LIST -->
            <v-col v-if="course.chapters.length > 1"
                cols="auto" xs="12" sm="12" lg="4" justify="center" text-align="center"
                style="margin-top: 80px" class="pl-15">
                <h2 color="#29324A" class="margin-chapter-list-heading">
                    {{ `kurset indeholder:  ${numOfChapters} kapitler ` }}
                </h2>
                <ChapterCard  v-for="(chapter, index) in course.chapters" :key="chapter.id"
                    :title="chapter.cardTitle" :subtitle="chapter.cardSubTitle"
                    :chapterId="chapter.id" :chapterListIndex="index" :courseId="course.id"
                    :duration="chapter.duration" :activeChapter="activeChapter"
                    class="chapter-card-spacing" @clicked="onChapterClick" />
            </v-col>
        </v-row>
        <authorization-modal/>
    </v-container>
</template>
<script>

import { v4 as uuidv4 } from 'uuid';
import ChapterCard from '../components/ChapterCard.vue';
import ChapterContentCard from '../components/ChapterContentCard.vue';
import AuthorCard from '../components/AuthorCard.vue';
import LinkCard from '../components/LinkCard.vue';
import { getCourse, postLog } from '../plugins/api_requests';
import AuthorizationModal from '../components/AuthorizationModal.vue';

export default {
    name: 'Course',

    components: {
        ChapterCard,
        ChapterContentCard,
        AuthorCard,
        LinkCard,
        AuthorizationModal,
    },

    data() {
        return {
            course: [],
            loading: true,
            errorMessage: [],
            activeChapter: null,
        };
    },

    beforeMount() {
        this.getCourseFromAPI(this.$route.params.courseId);
        this.activeChapter = this.$route.params.chapterId;
    },

    mounted() {
        // add UUID to user if it does not exist in cookies.
        if (!this.$cookies.isKey('uuid')) {
            this.$cookies.set('uuid', uuidv4(), '6y');
        }

        console.log('Course is mounted');
    },

    watch: {
        activeChapter: async function shouldAutoplay() {
            await this.delay(1000);
            if (this.course.chapters) {
                if (this.course.chapters[this.activeChapter].autoPlayVideo) {
                    document.getElementById('chapterVideo').play();
                }
            }
        },
        loading: async function setHeaderColor() {
            await this.delay(200);
            console.log('watching');
            this.$refs.sub.style.backgroundColor = this.course.primaryColor;
            console.log(this.course.chapters);
        },
        immediate: true,
    },

    computed: {
        videoUrl: function constructVideoUrl() {
            return `${process.env.VUE_APP_STORAGE_URL}${this.course.chapters[this.activeChapter].videoUrl
            }`;
        },
        numOfChapters: function getNumofChapters() {
            return this.course.chapters.length;
        },
    },

    methods: {
        delay(time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        },

        async getCourseFromAPI(id) {
            await getCourse(id, {
                params: {
                    user_uuid: this.$cookies.get('uuid'),
                    municipality_id: this.$cookies.get('municipality'),
                },
            })
                .then((result) => {
                    this.course = result.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.errorMessage = 'Kunne ikke finde kursus.';
                });
        },

        onChapterClick(chapter) {
            this.activeChapter = chapter;
            console.log(this.course.chapters[chapter].id);
            // post logging to API
            postLog({
                params: {
                    action: 'Go to chapter',
                    chapter_id: this.course.chapters[chapter].id,
                    course_id: this.course.id,
                    user_uuid: this.$cookies.get('uuid'),
                    municipality_id: this.$cookies.get('municipality'),
                },
            })
                .then((result) => {
                    this.municipality = result.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.municipalityErrorMessage = 'Kunne ikke hente kommune.';
                });

            // Update router chapter id
            this.$route.params.chapterId = chapter;
            this.$router.replace({ params: this.$route.params });
        },

        onGoBackClick() {
            this.$router.push({
                name: 'municipality_home',
                params: { municipalityId: this.$cookies.get('municipality') },
            });
        },
    },
};
</script>
<style scoped>
.go-back {
    color: var(--v-course_text-base);
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    width: 200px;
}

.heading-wrapper {
    display: flex;
    text-align: left;
    justify-content: left;
}

.subheading-wrapper {
    justify-content: center;
    margin-top: 15px;
    max-width: 500px;
}

.text {
    color: var(--v-course_text-base);
}

.padding {
    width: 100%;
}

.course-background {
    background-color: var(--v-course_background-base) !important;
    width: 100vw !important;
}

.video-spacer {
    margin-top: 30px;
}

.margin-chapter-list-heading {
    margin-bottom: 30px;
}

.chapter-card-spacing {
    margin-bottom: 15px;
}
</style>
