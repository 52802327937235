<template>
    <v-card class="rounded-xl card-style flat" flat color="#FFFFF">
        <div class="content-wrapper">
            <v-row>
                <v-col cols="auto" xs="12" sm="12" md="12" lg="4">
                    <v-img :src="this.imageUrl" class="author-image"></v-img>
                </v-col>
                <v-col cols="auto" xs="12" sm="12" md="12" lg="8">
                    <v-list-item-content>
                        <v-list-item-title class="card-title mt-5">
                            {{ this.name }}
                        </v-list-item-title>
                        <div class="mb-2 duration">
                            {{ this.jobTitle }}
                        </div>
                        <p class="subtitle">
                            {{ this.body }}
                        </p>
                    </v-list-item-content>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'AuthorCard',

    props: ['name', 'title', 'body', 'image', 'jobTitle'],

    data() {
        return {
            municipality: [],
            show: false,
            loading: true,
            municipalityErrorMessage: [],
        };
    },

    computed: {
        imageUrl: function constructImageUrl() {
            return `${process.env.VUE_APP_STORAGE_URL}${this.image}`;
        },
    },

    methods: {},
};
</script>

<style scoped>
.subtitle {
    color: var(--v-chapter_card_text-base) !important;
    font-weight: 500;
    font-size: 16px;
    font-family: "Circe-rounded", sans-serif;
    line-height: 1.6;
}

.card-padding {
    padding-left: 20px;
    padding-bottom: 10px;
}

.duration {
    color: var(--v-chapter_card_subheader-base) !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-family: "Circe-rounded", sans-serif;
    text-transform: none !important;
}

.card-title {
    color: var(--v-chapter_card_text-base) !important;
    font-size: 30px;
    font-weight: 600 !important;
    font-family: "Circe-rounded", sans-serif;
    text-transform: none !important;
}

.content-wrapper {
    padding-left: 30px;
    padding-right: 20px;
    padding-bottom: 20px;
}
.card-style {
    margin-top: 15px;
    margin-bottom: 30px;
    max-width: 800px;
}

.author-image {
    object-fit: cover;
    width: 200px;
    height: 200px;
    max-height: 250px;
    max-width: 250px;
    border-radius: 100%;
}
</style>
