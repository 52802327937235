<template>
    <v-card class="rounded-xl card-style outlined flat" flat color="#FFFFF">
        <div class="content-wrapper">
            <v-list-item-content>
                <div class="subtitle" v-html="this.body"></div>
            </v-list-item-content>
        </div>
    </v-card>
</template>

<script>
export default {
    name: 'ChapterCard',

    props: ['title', 'body', 'chapterId'],

    data() {
        return {
            municipality: [],
            show: false,
            loading: true,
            municipalityErrorMessage: [],
        };
    },

    methods: {
        goToCourse(courseId) {
            this.$router.push({
                name: 'course',
                params: { id: courseId },
            });
        },
    },
};
</script>

<style scoped>
.subtitle {
    color: var(--v-course_text-base);
    font-weight: 500;
    font-size: 16px;
    font-family: "Circe-rounded", sans-serif;
    line-height: 1.6;
}

.card-padding {
    padding-left: 20px;
    padding-bottom: 10px;
}

.content-wrapper {
    padding-right: 20px;
    padding-bottom: 20px;
}
.card-style {
    border: 0px solid #707070;
    margin-top: 0px;
    margin-bottom: 0px;
}
</style>
