<template>
    <v-hover v-slot="{ hover }">
        <v-card
            @click="goToUrl(url)"
            class="card rounded-xl"
            :elevation="hover ? 4 : 2"
        >
            <v-card class="iconArea" :color="color">
                <v-icon class="icon" size="40">{{ icon }}</v-icon>
            </v-card>
            <div class="textArea">
                <p class="link-text">{{ displayName }}</p>
            </div>
        </v-card>
    </v-hover>
</template>

<script>
export default {
    name: 'LinkCard',

    props: ['url', 'icon', 'displayName', 'color'],

    data() {
        return {};
    },

    mounted() {
        console.log(this.color);
    },

    computed: {
        aValue: function defineValue() {
            return 'hello world';
        },
    },

    methods: {
        goToUrl(url) {
            console.log(url);
            window.open(url);
        },
    },
};
</script>

<style scoped>
.card {
    background-color: var(--v-link_card_background-base);
    width: 355px;
    height: 92px;
    border-radius: 20px;
    display: flex;
    cursor: pointer;
}
.iconArea {
    height: 100%;
    width: 70px;
    background-color: var(--v-link_card_icon_background-base);
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
    display: flex;
}

.icon {
    margin: auto;
    padding: auto;
    size: 20px;
    color: var(--v-link_icon-base);
}

.textArea {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
}

.link-text {
    padding: 0px !important;
    margin: 0px !important;
    text-decoration: underline;
}
</style>
